* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #323A25;
}

a:link, a:visited {
  text-decoration: none;
  color: #323A25;
}